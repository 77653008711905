<template>
  <div class="container">
    <van-nav-bar title="当前城市" :fixed="true">
      <template #left>
        <van-icon :size="24" @click="$router.go(-1)" color="#141212" name="arrow-left" slot="left" />
      </template>
    </van-nav-bar>
    <div class="w_c_out">
      <div class="w_top d_f ali_c j_c">
        <div class="d_f ali_c">
          <span class="num">{{today.temp_day}}</span>
          <div>
            <div class="danwei"></div>
            <div class="w_text">{{today.tianqi}}</div>
          </div>
        </div>
        <div class="t_r flex_1">
          <div class="time">{{today.update_time}}</div>
          <div class="choose cur_p" @click="isChoose = true">
            <span>{{nowCity}}</span>
            <img src="../assets/img/w_right.png" alt="">
          </div>
        </div>
      </div>
      <div class="w_i_out">
        <div class="wi_top">
          现在<span>{{today.tianqi}}</span>。最高温度<span>{{today.temp_day}}</span>。空气质量：<span>{{info.air_quality.status}}</span>
        </div>
        <div class="w_item">
          <div class="w_items d_f ali_c" v-for="(item, index) in info.seven_tianqi" :key="index">
            <div class="week">
              <span>{{item.name}}</span>
            </div>
            <div class="t_a flex_1">
              <img :src="item.httpsiconsitetianqi" alt="">
            </div>
            <div class="tem">
              <span class="high">{{item.wendu}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="changeAreaPop p_f" v-if="isChoose">
      <van-nav-bar title="切换城市">
        <template #right>
          <span class="cur_p" @click="isChoose = false">取消</span>
        </template>
      </van-nav-bar>
      <div class="cp_content">

        <div class="ca_c_addr_out">
          <div class="caca_title">当前定位</div>
          <div class="caca_addr t_a d_i ac">{{nowCity}}</div>
        </div>
        <div class="ca_c_addr_out ca_all_addr_out">
          <div class="caca_title">全部区县/市</div>
          <div>
            <div class="caca_addr t_a d_i" v-for="(item,index) in citys" :key="index" @click="changeArea(item)">{{item.name}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { NavBar, Icon, Toast, Dialog } from 'vant'
  import  api  from '../api/index'
  import  PinYin  from '../util/pinyin/vPInYin'
  export default {
    name: "weather",
    components:{
      'van-icon':Icon,
      'van-nav-bar':NavBar,
    },
    data() {
      return {
        isChoose:false,
        // nowCity:localStorage.getItem('area') ? JSON.parse(localStorage.getItem('area')).name : JSON.parse(localStorage.getItem('map')).addressComponent.district,
        nowCity:'峨眉山市',
        today:{temp_day:'',},
        info:{air_quality:{}},
        citys:[]
      }
    },
    methods: {
      //切换地区
      changeArea(row){
        Dialog.confirm({
          message: '是否选择当前区县？',
        })
          .then(() => {
            // on confirm
            this.nowCity = row.name;
            this.weatherjson(row.cityCode);
            localStorage.setItem('area',JSON.stringify(row))
          })
          .catch(() => {
            // on cancel
          });
      },
      // 首页获取天气
      weatherjson(code){
        api.weatherjson(code ? code : PinYin.chineseToPinYin(this.nowCity)).then(res => {
        // api.weatherjson(code ? code : PinYin.chineseToPinYin('金牛区')).then(res => {
          this.today = JSON.parse(JSON.stringify(res.weatherJson.today));
          this.info = JSON.parse(JSON.stringify(res.weatherJson));
          this.isChoose = false;
        }).catch(err => {
          Toast('为您切换到峨眉山市');
          this.nowCity = '峨眉山市';
          this.weatherjson()
        })
      },
      //获取全部区县
      getAllArea(){
        api.getAllArea('qy-ls').then(res => {
          this.citys = JSON.parse(JSON.stringify(res.data));
        })

      },
    },
    created(){

      this.getShareInfo(
        this.$global.shareTitle,
        this.$global.visitUrl,
        this.$global.shareImage,
        this.$global.shareIntro,
      );
      let code  = localStorage.getItem('area') ? JSON.parse(localStorage.getItem('area')).cityCode : '';
      if(localStorage.getItem('map')){
        this.nowCity = JSON.parse(localStorage.getItem('map')).addressComponent.district;
      }
      if(localStorage.getItem('area')){
        this.nowCity = JSON.parse(localStorage.getItem('area')).name
      }
      this.weatherjson(code);
      this.getAllArea();
    }
  }
</script>

<style scoped lang="scss">
  .changeAreaPop{
    .cp_content{
      .ca_c_addr_out{
        &.ca_all_addr_out{
          .caca_addr{
            margin-bottom: 20px;
            margin-right: 15px;
          }
        }
        .caca_addr{
          width: calc((100% - 30px) / 3);
          height:38px;
          border-radius:2px;
          line-height: 38px;
          font-size:12px;
          font-weight:500;
          margin-bottom: 29px;
          box-sizing: border-box;
          color: #303030;
          background: #F0F0F0;
          &:nth-child(3n){
            margin-right: 0;
          }
        }
        .caca_title{
          font-size: 17px;
          font-weight: bold;
          color: #303030;
          margin-bottom: 15px;
        }
      }
      padding: 26px;
    }
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 99;
    background-color: white;
  }
  .w_c_out{
    .w_i_out{
      .w_item{
        .w_items{
          img{
            width: 23px;
          }
          .tem{
            font-size: 13px;
            font-weight: bold;
            color: #303030;
          }
          .week{
            font-size: 12px;
            font-weight: 500;
            color: #303030;
            width: 60px;
          }
          padding-top: 18px;
          &:nth-child(1){
            padding-bottom: 10px;
            border-bottom: 1PX solid #CBCBCB;
          }
        }
        padding: 0 15px 30px;
      }
      .wi_top{
        font-size: 14px;
        font-weight: bold;
        color: #FFFFFF;
        padding: 0 14px;
        line-height: 56px;
        height: 56px;
        background: linear-gradient(-90deg, rgba(22, 121, 212, 0.85), rgba(38, 158, 235, 0.86), rgba(48, 161, 233, 0.85));
      }
      background: #F5F5F5;
      border-radius: 9px;
      overflow: hidden;
    }
    .w_top{
      .t_r{
        line-height: 0.9;
        .choose{
          img{
            width: 13px;
            vertical-align: middle;
            margin-left: 3px;
            position: relative;
            top: 2px;
          }
          span{
            font-size: 21px;
            font-weight: 500;
            color: #FFFFFF;
            vertical-align: middle;
          }
        }
        .time{
          font-size: 19px;
          font-weight: bold;
          color: #FFFFFF;
        }
      }
      .w_text{
        font-size: 21px;
        font-weight: 500;
        color: #FFFFFF;
        position: relative;
        top: -6px;
      }
      .danwei{
        font-size: 30px;
        font-weight: 400;
        color: #FFFFFF;
      }
      .num{
        font-size: 63px;
        font-weight: 400;
        color: #FFFFFF;
      }
      margin-bottom: 19px;
      border-radius: 9px;
      background: url("../assets/img/w_banner.png") no-repeat;
      background-size: 100% 100%;
      padding: 46px 22px 46px 34px;
      margin-top: 10px;
    }
    padding: 40px 24px 17px 24px;
  }
</style>
